div.driftstone-wrapper {
  max-width: 900px;
  margin: 0 auto;
}

div.driftstone-wrapper div.stone-select-container {
  padding: 15px;
  overflow: auto;
  display: flex;
}

div.driftstone-wrapper div.stone-select-container div.stone-select {
  margin: 0 auto;
}

div.driftstone-wrapper div.stone-select-container img.stoneIcon {
  cursor: pointer;
  width: 50px;
  /* filter: grayscale(100%); */
}

div.driftstone-wrapper div.stone-select-container img.stoneIcon-selected {
  cursor: pointer;
  width: 50px;
  border: 2px solid #c0b08e;
  border-radius: 10%;
}

div.driftstone-wrapper div.stone-skill-search {
  padding: 0 20px;
}

div.driftstone-wrapper div.driftstone-table {
  margin: 0 auto;
  margin-top: 20px;
  overflow-x: auto;
  max-width: 600px;
  max-height: 550px;
}