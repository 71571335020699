div.status-wrapper {
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 50px;
}

div.status-wrapper img.status-icon {
  height: 20px;
}

div.status-wrapper div.status-agenda-divider {
  margin-top: 100px;
  margin-bottom: 15px;
}

div.status-wrapper div.monster-status-star {
  max-width: 500px;
  width: 100%;
  text-align: center;
}

div.status-wrapper div.monster-status-table {
  max-width: 500px;
  max-height: 300px;
}

div.status-wrapper div.monster-status-table img.monster-status-icon {
  height: 20px;
  margin-right: 3px;
}

div.status-wrapper div.status-calc-input {
  padding: 10px;
}