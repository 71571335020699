button.menuButton {
    width: 100%;
    color: #564A30 !important;
}

div.menuList {
    width: 100%;
    border: 1px solid #C0B08E;
    color: #564A30 !important;
}

div.menuList li {
    font-size: 0.875rem;
}

img.menuIcon {
    height: 20px;
    margin-right: 10px;
}