div.calculator-wrapper {
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 50px;
}

div.calculator-wrapper {
  margin-bottom: 10px;
  padding: 15px;
  overflow-x: auto;
  display: flex;
}

div.calculator-wrapper div.section-divider {
  margin-bottom: 10px;
}

div.calculator-wrapper div.calc-type-select {
  width: 100%;
}

div.calculator-wrapper div.weapon-type-select {
  margin: 0 auto;
}

div.calculator-wrapper div.target-monster-select,
div.calculator-wrapper div.skill-select {
  text-align: center;
}

div.calculator-wrapper div.param-input {
  margin-bottom: 10px;
}

div.calculator-wrapper div.target-monster-list {
  width: 100%;
  margin-bottom: 10px;
}

div.calculator-wrapper div.weapon-grade-list {
  width: 95%;
  margin-bottom: 10px;
}

div.calculator-wrapper div.weapon-grade-select img,
div.calculator-wrapper div.target-monster-select img,
li.weapon-name img,
li.target-monster img {
  max-height: 23px;
}

div.damage-calc-wrapper div.damage-table-filter {
  margin-bottom: 5px;
}