/**
 * お知らせ一覧ページ
 */
div.notification-item {
  margin: 10px 20px;
  width: 100%;
}

div.notification-item div.notification-status {
  margin-right: 10px;
}

/**
 * お知らせ記事作成ページ
 */
div.article-title {
  padding: 20px;
}

div.article-title div.article-title-input {
  width: 100%;
}

div.article-editor,
div.article-viewer {
  padding: 20px;
}