@font-face {
    font-family: "Fulbo-Premier";
    src: local("Fulbo-Premier"), url(../fonts/FulboPremier-PK7D7.otf) format("opentype");
}

@font-face {
    font-family: "Digital-Display";
    src: local("Digital-Display"), url(../fonts/DigitalDisplayRegular-ODEO.ttf) format("truetype");
}

div.drop-item-container {
    max-width: 900px;
    margin: 0 auto;
}

div.drop-data-container {
    padding: 10px;
}

div.drop-item-menu {
    margin-bottom: 15px;
    width: 100%;
}

div.drop-item {
    margin-bottom: 10px;
}

div.drop-item img {
    height: 40px;
}

div.drop-data {
    width: 100%;
}

div.drop-data div.drop-percent {
    margin-bottom: 10px;
    font-size: 3.5rem;
    color: #C0B08E;
}

div.drop-data div.drop-percent div.animatedDiv {
    display: inline;
    margin-right: 5px;
    font-family: "Fulbo-Premier", "Roboto", "Helvetica", "Arial", sans-serif;
}

img.purple-star {
    height: 20px;
}

div.MuiCardContent-root:last-child {
    padding-bottom: 10px;
}

div.monster-container-wrapper {
    max-width: 900px;
    margin: 0 auto;
}

div.monster-container {
    padding: 5px;
}

div.monster-container img.monsterIcon {
    width: 100%;
}

div.monster-hp-wrapper {
    margin: 0 auto;
    max-width: 900px;
}

div.hitpoint-paper {
    overflow: hidden;
}

div.hitpoint-table {
    max-height: 400px;
}

div.hitpoint-table img.monsterIcon {
    height: 30px;
}