@font-face {
  font-family: "Fulbo-Premier";
  src: local("Fulbo-Premier"), url(../fonts/FulboPremier-PK7D7.otf) format("opentype");
}

@font-face {
  font-family: "Digital-Display";
  src: local("Digital-Display"), url(../fonts/DigitalDisplayRegular-ODEO.ttf) format("truetype");
}

div.maintenance,
div.error {
  width: 100%;
  width: 100vw;
  height: 100vh;
}

header.navbar {
  margin-bottom: 5px;
}

header.navbar div.logo {
  flex-grow: 1;
  font-weight: 600;
}

div.header {
  margin-bottom: 5px;
  padding: 7px;
  background-color: #C0B08E;
  color: white;
  text-align: center;
}

header.notification {
  top: auto !important;
  bottom: 25px;
  position: fixed;
  text-align: right;
}

div.monster-star {
  margin-bottom: 20px;
}

div.monster-star img {
  width: 30px;
}

div.info-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 5px;
  transform: translate(-50%, -50%);
  max-width: 700px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

div.bottom-nav {
  margin-top: 30px;
  margin-bottom: 70px;
  width: 100%;
}