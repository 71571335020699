header.footer {
    top: auto !important;
    bottom: 0;
    position: fixed;
    background-color: #C0B08E !important;
}

header.footer a {
    color: white;
    font-weight: bold;
    text-decoration: none;
}