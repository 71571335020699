div.motion-list-wrapper {
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 50px;
}

div.motion-list-wrapper div.weapon-select-container {
  margin-bottom: 10px;
  padding: 15px;
  overflow: auto;
  display: flex;
}