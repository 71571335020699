div.monster-drop-wrapper {
    max-width: 900px;
    margin: 0 auto;
}

/**
 * 基本報酬枠
 */
div.normal-drop {
    margin-bottom: 20px;
    padding: 5px;
    text-align: center;
}

div.normal-drop .percent-box {
    padding-top: 16px;
    padding-bottom: 16px;
    border: 2px solid #C0B08E;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

div.normal-drop .percent-number {
    display: inline;
    font-family: "Digital-Display", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    color: #FFB821;
}

div.normal-drop .percent-label {
    display: inline;
    color: #C0B08E;
}

div.normal-drop .attempts-box {
    border: 1px solid #C0B08E;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #C0B08E;
    color: white;
}

div.normal-drop .attempts-try {
    display: inline;
    font-weight: bold;
}

div.normal-drop .attempts-total {
    display: inline;
}

/**
 * 部位破壊報酬枠
 */
div.breakable-drop {
    margin-bottom: 50px;
    text-align: center;
}

div.breakable-drop img {
    max-width: 100%;
}

div.monster-spec-wrapper {
    max-width: 900px;
    margin: 0 auto;
}

div.star-selector {
    text-align: center;
}

div.star-selector div {
    width: 100%;
}