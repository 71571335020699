div.control-panel {
    margin-top: 20px;
    margin-bottom: 50px;
    width: 100%;
}

.spread-url {
    width: 80%;
}

.import {
    text-align: center;
}

.publish {
    width: 50%;
}